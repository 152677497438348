<script>
import ExperienceRecordModal from './ExperienceRecordModal.vue'
export default {
  components: { ExperienceRecordModal },
  name: 'ExperienceRecord',
  props: {
    item: {
      type: Object
    },
    title: {
      type: String
    },
    subTitle: {
      type: String
    },
    dateInit: {
      type: String
    },
    dateEnd: {
      type: String
    },
    validation: {
      type: Object
    }
  },
  data () {
    return {
      openModal: false,
      oldData: null
    }
  },
  watch: {
    openModal () {
      if (this.openModal) {
        this.oldData = { ...this.item }
      }
    }
  },
  methods: {
    close () {
      if (this.item.new) {
        this.$emit('remove')
      } else {
        this.$emit('cancelEdit', this.oldData)
      }
      this.validation.$reset()
      this.openModal = false
    },
    save () {
      this.validation.$touch()
      if (this.validation.$error) {
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'error',
          title: this.$t('global:validation.form.required')
        })
      } else {
        this.item.new = false
        this.openModal = false
      }
    }
  }
}
</script>
<template>
<div class="experience-record-wrapper">
  <div class="experience-record--container">
    <div class="experience-record--left">
      <p class="experience-record--title">{{ title }}<span v-if="subTitle"> - {{ subTitle }}</span></p>
      <div class="experience-record--date">
        <p>{{ dateInit }} {{ dateEnd ? '-' : '' }} {{ dateEnd }}</p>
      </div>
    </div>
    <div class="experience-record--right">
      <v-btn class="btn mr-2" color="#fff" @click="openModal = true"><v-icon size="18" :color="getPrimaryColor">mdi-pencil</v-icon></v-btn>
      <v-btn class="btn" color="#fff" @click="$emit('remove')"><v-icon size="18" :color="getPrimaryColor">mdi-delete</v-icon></v-btn>
    </div>
    <experience-record-modal :canSubmit="validation.$error" v-if="openModal || (item && item.new)" @save="save" @close="close">
      <template v-slot:fields>
        <slot name="fields"></slot>
      </template>
    </experience-record-modal>
  </div>
      <span v-show="this.validation.$error" class="form-input-message"><v-icon class="form-input-icon">mdi-information</v-icon>{{ $t('global:validation.not.complete')}}</span>
  </div>
</template>
<style lang="scss">
.experience-record-wrapper {
    margin-bottom: 16px;
}
.experience-record--container {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: #FAFAFA;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  .experience-record--left {
      width: 70%;
    p.experience-record--title {
      font-size: 18px;
      line-height: 1.2em;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      align-items: center;
      color: #000000;
      margin-bottom: 8px;
      span {
        color: #7E7D77;
        font-weight: normal;
        margin-left: 4px;
      }
    }
    .experience-record--date {
      text-align: left;
    }
    .experience-record--date p {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #7E7D77;
      margin-bottom: 0;
    }
  }
  .experience-record--right {
    display: flex;
    align-items: center;
    .v-btn {
      height: 30px;
      max-height: 30px;
      width: 30px;
      min-width: unset;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
