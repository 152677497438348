<script>
export default {
  name: 'ExperienceRecordModal',
  props: {
    canSubmit: {
      type: Boolean
    }
  }
}
</script>
<template>
  <v-dialog :value="true" width="900px" max-width="100%" z-index="110" @click:outside="$emit('close')" @keydown.esc="$emit('close')">
    <v-card class="experience-record--modal">
      <div class="experience-record--head">
        <h2 class="experience-record--modal-title">{{ $t('global:edit') }}</h2>
        <v-icon class="cursor-pointer" @click="$emit('close')">mdi-close</v-icon>
      </div>
      <div class="experience-record--body">
        <slot name="fields"></slot>
      </div>
      <div class="experience-record--actions">
        <v-btn class="btn transform-unset mr-2" :color="getPrimaryColor" outlined @click="$emit('close')">{{ $t('global:cancel') }}</v-btn>
        <v-btn class="btn transform-unset white--text" :color="getPrimaryColor" :disabled="canSubmit" @click="$emit('save')">{{ $t('global:save') }}</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.experience-record--modal {
  .experience-record--head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    background: #FAFAFA;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    .experience-record--modal-title {
      font-family: $lato;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #454545;
      text-align: left;
    }
  }
  .experience-record--body {
    padding: 16px 24px;
  }
  .experience-record--actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    background: #FAFAFA;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
}
</style>
